import { createContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import localstorage from 'local-storage';
import { backend_api } from '../helper/constant';



export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState('');
    const [loadingUserData, setLoadingUserData] = useState(true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [notification, setNotification] = useState([]);



    const handleCookies = (type = '', data = '') => {
        if (type === 'add') {
            localstorage.set('blockstarUser', data);
        }
        else {
            localstorage.remove('blockstarUser');
        }
    }

    const handleGetCookies = (name = '') => {
        return localstorage.get(name);
    }


    useEffect(() => {


        let userCookies = handleGetCookies('blockstarUser');


        if (userCookies === null || !userCookies) {
            handleCookies()
            setUser('')
            setLoadingUserData(false);

            if (pathname === '/channel' || pathname === '/login' || pathname === '/signup' || pathname === '/confirm-signup' || pathname === '/forgot-password' || pathname === '/reset-password' || pathname.includes('/room') || pathname.includes('/channel/')) {

            }
            else {
                navigate('/login');
            }

        }
        else {
            const formData = new FormData();
            formData.append('token', userCookies);
            backend_api.post('/tokenverify.php', formData)
                .then((response) => {
                    if (response && response.data && response.data.status === 'error') {
                        handleCookies();
                        window.location.reload();
                    }
                    else {
                        if (response.data && response.data.data && response.data.data.data) {
                            setLoadingUserData(false);
                            setUser(response.data?.data?.data);
                        }
                        else {
                            handleCookies();
                            window.location.reload();
                        }

                    }
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, pathname])



    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
                loadingUserData,
                handleGetCookies,
                handleCookies,
                setNotification,
                notification
            }}
        >{children}</AuthContext.Provider>
    )
}