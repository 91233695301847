import React, { useEffect, useState, useCallback, useContext } from 'react';
import { backend_api } from '../helper/constant';
import Layout from '../components/Layout';
import Select from 'react-select';
import { GlobalContext } from '../context/GlobalContext';

export default function Calculator() {
    const { theme } = useContext(GlobalContext)
    const customStyles = (isDarkTheme) => ({
        control: (provided) => ({
            ...provided,
            backgroundColor: isDarkTheme ? '#333' : provided.backgroundColor,
            borderColor: isDarkTheme ? '#555' : provided.borderColor,
            color: isDarkTheme ? '#fff' : provided.color
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: isDarkTheme ? '#333' : provided.backgroundColor,
            color: isDarkTheme ? '#fff' : provided.color
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: isDarkTheme
                ? state.isFocused
                    ? '#555'
                    : '#333'
                : provided.backgroundColor,
            color: isDarkTheme ? '#fff' : provided.color
        }),
        singleValue: (provided) => ({
            ...provided,
            color: isDarkTheme ? '#fff' : provided.color
        }),
        input: (provided) => ({
            ...provided,
            color: isDarkTheme ? '#fff' : provided.color
        })
    });
    
    const [coins, setCoins] = useState({ cryptocurrency: {}, currency: {} });
    const [quotes, setQuotes] = useState({});
    const [isSwaped, setIsSwaped] = useState(false);
    const [selectedOption, setSelectedOption] = useState({
        value: "BTC",
        label: "[BTC] Bitcoin (cryptocurrency)"
    });
    const [selectedCurrency, setSelectedCurrency] = useState({
        value: "USD",
        label: "[USD] US Dollar (currency)"
    });
    const [cryptoOptions, setCryptoOptions] = useState([]);
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [conversionResult, setConversionResult] = useState(0);
    const [amount, setAmount] = useState('');

    useEffect(() => {
        const fetchCoins = async () => {
            try {
                const response = await backend_api.get(`get-coin-list.php`);
                if (response && response.data && response.data.data) {
                    setCoins(response.data.data.coins_list);
                    setQuotes(response.data.data.coins_quotes);
                }
            } catch (error) {
                console.error('Error fetching coins:', error);
            }
        };

        fetchCoins();
    }, []);

    useEffect(() => {
        calculateConversion();
    }, [selectedOption, selectedCurrency, amount]);

    const handleInputChange = useCallback((inputValue) => {
        if (!inputValue) {
            setCryptoOptions([]);
            return;
        }

        const filteredOptions = Object.keys(coins.cryptocurrency).filter(symbol =>
            coins.cryptocurrency[symbol].toLowerCase().includes(inputValue.toLowerCase()) ||
            symbol.toLowerCase().includes(inputValue.toLowerCase())
        ).map(symbol => ({
            value: symbol,
            label: `[${symbol}] ${coins.cryptocurrency[symbol]} (cryptocurrency)`
        }));

        setCryptoOptions(filteredOptions);
    }, [coins.cryptocurrency]);

    const handleInputChange2 = useCallback((inputValue) => {
        if (!inputValue) {
            setCurrencyOptions([]);
            return;
        }

        const filteredOptions = Object.keys(coins.currency).filter(symbol =>
            coins.currency[symbol].toLowerCase().includes(inputValue.toLowerCase()) ||
            symbol.toLowerCase().includes(inputValue.toLowerCase())
        ).map(symbol => ({
            value: symbol,
            label: `[${symbol}] ${coins.currency[symbol]} (currency)`
        }));

        setCurrencyOptions(filteredOptions);
    }, [coins.currency]);

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };

    const handleChange2 = (selectedOption) => {
        setSelectedCurrency(selectedOption);
    };

    const handleSwap = () => {
        const temp = selectedOption;
        setSelectedOption(selectedCurrency);
        setSelectedCurrency(temp);
        setIsSwaped(!isSwaped);
    };

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    const calculateConversion = () => {
        if (quotes[selectedOption.value] && quotes[selectedCurrency.value]) {
            const cryptoQuote = parseFloat(quotes[selectedOption.value].quote);
            const currencyQuote = parseFloat(quotes[selectedCurrency.value].quote);
            const conversion = (amount * cryptoQuote) / currencyQuote;
            setConversionResult(conversion);
        }
    };

    return (
        <Layout>
            <section id="content" className="container">
                <header>
                    <div className="row">
                        <div className="col s12">
                            <h1 className="primary-text">
                                Cryptocurrency calculator
                            </h1>
                            <div className="divider"></div>
                        </div>
                    </div>
                </header>
                <article className="section">
                    <section>
                        <div className="row">
                            <div className="col s12">

                                <div className="row">
                                    <div className="input-field col s12 l5">
                                        <input
                                            id="amount"
                                            placeholder="Enter Amount"
                                            type="text"
                                            value={amount}
                                            onChange={handleAmountChange}
                                        />
                                        <label htmlFor="amount">Amount</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col s12 l5 right-left calculator-result">
                                        <span id="result-amount-from">Select Coin</span>
                                    </div>
                                    <div className="col s12 l2 center-align calculator-result">

                                    </div>
                                    <div className="col s12 l5 left-align calculator-result">
                                        <span>Select Currency</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-field col s12 l5">
                                        <label>Select Coin</label>
                                        {isSwaped ? (
                                            <Select
                                                id="convert-to"
                                                value={selectedCurrency}
                                                onChange={handleChange2}
                                                onInputChange={handleInputChange2}
                                                options={currencyOptions}
                                                placeholder="Type to search for a currency..."
                                                noOptionsMessage={() => 'Type to search...'}
                                            />
                                        ) : (
                                            <Select
                                                id="convert-from"
                                                value={selectedOption}
                                                onChange={handleChange}
                                                onInputChange={handleInputChange}
                                                options={cryptoOptions}
                                                placeholder="Type to search for a coin..."
                                                noOptionsMessage={() => 'Type to search...'}
                                                styles={customStyles(theme === 'dark' ? true : false    )}
                                            />
                                        )}
                                    </div>

                                    <div className="input-field col s12 l2 center-align">
                                        <button
                                            id="calculator-swap-button"
                                            className="btn-floating waves-effect waves-light btn"

                                        >
                                            <i className="material-icons left">compare_arrows</i>
                                        </button>
                                    </div>
                                    <div className="input-field col s12 l5">
                                        <label>Select Currency</label>
                                        {isSwaped ? (
                                            <Select
                                                id="convert-from"
                                                value={selectedOption}
                                                onChange={handleChange}
                                                onInputChange={handleInputChange}
                                                options={cryptoOptions}
                                                placeholder="Type to search for a coin..."
                                                noOptionsMessage={() => 'Type to search...'}
                                                styles={customStyles(theme === 'dark' ? true : false    )}
                                            />
                                        ) : (
                                            <Select
                                                id="convert-to"
                                                value={selectedCurrency}
                                                onChange={handleChange2}
                                                onInputChange={handleInputChange2}
                                                options={currencyOptions}
                                                placeholder="Type to search for a currency..."
                                                noOptionsMessage={() => 'Type to search...'}
                                                styles={customStyles(theme === 'dark' ? true : false    )}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col s12 l5 right-align calculator-result">
                                        <span id="result-amount-from">{amount}</span>
                                        <span id="result-symbol-from">{" "}{selectedOption ? selectedOption.value : 'BTC'}</span>
                                    </div>
                                    <div className="col s12 l2 center-align calculator-result">
                                        <span>=</span>
                                    </div>
                                    <div className="col s12 l5 left-align calculator-result">
                                        <span id="result-amount-to">{conversionResult.toFixed(2)}</span>
                                        <span id="result-symbol-to">{" "}{selectedCurrency ? selectedCurrency.value : 'USD'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </article>
            </section>
        </Layout>
    );
}
