import React, { useState } from "react";
import { formatPrice } from "../helper/constant";
import { SaveBtn } from "./SaveBtn";
import AddCoin from "./AddCoin";
import EditCoin from "./EditCoin";
import { Link } from "react-router-dom";

const FavoriteTable = ({ coins, loading, type, setRefersh, portfolioData }) => {
    const [show, setShow] = useState(false);
    const [editshow, setEditShow] = useState(false);
    const [selectedCoin, setSelectedCoin] = useState([]);

    return (
        <>
            <header>
                <div class="flex items-center justify-between py-4">
                    <h1 class="text-primary text-xl font-bold">
                        Favorite coins
                    </h1>
                </div>
            </header>
            <div class="grid xl:grid-cols-4 md:grid-cols-2 gap-6 mb-6">
                <div class="col-xl-3 col-md-6">
                    <div class="card">
                        <div class="p-5">
                            <h3 class="text-2xl change-up" data-plugin="counterup">
                                ${portfolioData && portfolioData.current_value ? formatPrice(portfolioData.current_value) : 0}
                            </h3>
                            <span class="inline-flex items-center gap-1.5 py-0.5 px-1.5 text-xs font-medium bg-success text-white rounded me-1">Current Balance</span>
                        </div>
                    </div>
                </div>

                <div class="col-xl-3 col-md-6">
                    <div class="card">
                        <div class="p-5">
                            {portfolioData && parseFloat(portfolioData.current_value) - parseFloat(portfolioData.total_spent_sum) > 0 ? (
                                <h3 class="text-2xl change-up" data-plugin="counterup">
                                    {portfolioData.current_value && portfolioData.total_spent_sum ? `$${formatPrice(parseFloat(portfolioData.current_value) - parseFloat(portfolioData.total_spent_sum))}` : 0}
                                </h3>
                            ) : parseFloat(portfolioData.current_value) - parseFloat(portfolioData.total_spent_sum) < 0 ? (
                                <h3 class="text-2xl change-down" data-plugin="counterup">
                                    {portfolioData.current_value && portfolioData.total_spent_sum ? `$${formatPrice(parseFloat(portfolioData.current_value) - parseFloat(portfolioData.total_spent_sum))}` : 0}
                                </h3>
                            ) : (
                                <h3 class="text-2xl" data-plugin="counterup">
                                    $0
                                </h3>
                            )}
                            <span class="inline-flex items-center gap-1.5 py-0.5 px-1.5 text-xs font-medium bg-success text-white rounded me-1">
                                Total Portfolio Change
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-xl-3 col-md-6">
                    <div class="card">
                        <div class="p-5">
                            {portfolioData && parseFloat(portfolioData.total_spent_sum) > 0 ? (
                                <h3 class={`text-2xl ${parseFloat(portfolioData.current_value) - parseFloat(portfolioData.total_spent_sum) > 0 ? 'change-up' : 'change-down'}`} data-plugin="counterup">
                                    {`$${((parseFloat(portfolioData.current_value) - parseFloat(portfolioData.total_spent_sum)) / parseFloat(portfolioData.total_spent_sum) * 100).toFixed(2)}%`}
                                </h3>
                            ) : (
                                <h3 class="text-2xl" data-plugin="counterup">
                                    $0
                                </h3>
                            )}
                            <span class="inline-flex items-center gap-1.5 py-0.5 px-1.5 text-xs font-medium bg-success text-white rounded me-1">Total Profit / Loss</span>
                        </div>
                    </div>
                </div>

            </div>

            <div className="row">
                <div className="col s12">
                    <table id="coins-list" className="bordered highlight vertical-responsive-table">
                        <thead>
                            <tr>
                                <th>
                                    <a href="#sec">Cryptocurrency</a>
                                </th>
                                <th className="right-align">
                                    <a href="#sec">Price</a>
                                </th>
                                <th className="right-align">
                                    <a href="#sec">24h % Change</a>
                                </th>
                                <th className="right-align">
                                    <a href="#sec">Volume</a>
                                </th>
                                <th className="right-align">
                                    <a href="#sec">
                                        <i className="material-icons">arrow_drop_down</i>
                                        Market cap
                                    </a>
                                </th>
                                <th className="right-align">
                                    <a href="#sec">
                                        Holdings
                                    </a>
                                </th>
                                <th className="right-align">
                                    <a href="#sec">
                                        PNL
                                    </a>
                                </th>
                                <th className="right-align">
                                    <a href="#sec">
                                        Action
                                    </a>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading && coins && coins.map((coin, index) => (
                                <tr key={index} className={`coin-row coin-row-${coin.symbol}`} data-symbol={`${coin.symbol}`}>
                                    <td className="flex" data-title="Cryptocurrency">
                                        <span className="coin-number">{index + 1}</span>
                                        <a href="#sec" className="flex">
                                            <SaveBtn data={coin} favData={coins} setRefersh={setRefersh} />
                                            <img src={`assets/images/coins/thumb30/${coin.logo}`} alt={coin.name} title={coin.name} />
                                        </a>
                                        <span><a href={`/coin/${coin.symbol}`}>{coin.name}</a></span>
                                    </td>
                                    <td className="right-align" data-title="Price">
                                        <sup>$</sup><span className="coin-price-value">{formatPrice(coin.price, 8)}</span>
                                    </td>
                                    <td className="right-align" data-title="24h % Change">
                                        <span className={`coin-change_pct-value ${type === 1 ? 'change-up' : 'change-down'}`}>{coin.change_pct}%</span>
                                    </td>
                                    <td className="right-align" data-title="Volume">
                                        <span className="coin-volume-value">{coin.volume}</span> {coin.symbol}
                                    </td>
                                    <td className="right-align" data-title="Market cap">
                                        <sup>$</sup><span className="coin-market_cap-value">{formatPrice(coin.market_cap, 12)}</span>
                                    </td>
                                    <td className="right-align" data-title="Market cap">
                                        {coin && coin.total_spent_sum > 0 ? (
                                            <>
                                                <p>${coin && coin.total_spent_sum ? formatPrice(coin.total_spent_sum) : 0}</p>
                                                <p>{coin && coin.total_qty ? formatPrice(coin.total_qty) : 0} {coin?.symbol}</p>
                                            </>

                                        ) : (
                                            <>
                                                <p>-</p>
                                            </>
                                        )}

                                    </td>
                                    <td className="right-align" data-title="Market cap">
                                        {coin && coin.total_spent_sum > 0 ? (
                                            coin.total_spent_sum === 0 ? (
                                                <>
                                                    <p className="change-down">${coin && coin.total_pnl_dollars ? formatPrice(coin.total_pnl_dollars, 5) : 0}</p>
                                                    <p className="change-down">{coin && coin.percentage_pnl ? parseFloat(coin.percentage_pnl).toFixed(2) : 0}%</p>
                                                </>

                                            ) :
                                                coin.total_pnl_dollars < 0 ? (
                                                    <>
                                                        <p className="change-down">${coin && coin.total_pnl_dollars ? formatPrice(coin.total_pnl_dollars, 5) : 0}</p>
                                                        <p className="change-down">{coin && coin.percentage_pnl ? parseFloat(coin.percentage_pnl).toFixed(2) : 0}%</p>
                                                    </>

                                                ) : (
                                                    <>
                                                        <p className="change-up">${coin && coin.total_pnl_dollars ? formatPrice(coin.total_pnl_dollars, 5) : 0}</p>
                                                        <p className="change-up">{coin && coin.percentage_pnl ? parseFloat(coin.percentage_pnl).toFixed(2) : 0}%</p>
                                                    </>

                                                )) : (
                                            <>
                                                <p>-</p>
                                            </>
                                        )}
                                    </td>
                                    <td className="right-align" data-title="Market cap">
                                        <button type="button" onClick={() => {
                                            setShow(true)
                                            setSelectedCoin(coin)
                                        }} class="bg-blue-500 text-white px-3 py-1 mx-1 rounded hover:bg-blue-600">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                        <Link to={`/favorites/${coin?.id}`} class="bg-blue-500 text-white px-3 py-1 mx-1 rounded hover:bg-blue-600">
                                            <i class="fas fa-edit text-white"></i>
                                        </Link>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div >
            </div >
            {loading &&
                <div class="row">
                    <div class="col s12 center-align">
                        <button type="button" class="waves-effect waves-light btn-large"><i class="material-icons left">cloud_download</i>Loading...</button>
                    </div>
                </div>
            }
            <AddCoin show={show} setShow={setShow} coins={coins} selectedCoin={selectedCoin} setRefersh={setRefersh} />
            <EditCoin editshow={editshow} setEditShow={setEditShow} coins={coins} selectedCoin={selectedCoin} setRefersh={setRefersh} />
        </>
    );
};

export default FavoriteTable;
