import React from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";
import Home from './pages/Home';
import Fallers from './pages/Fallers';
import Risers from './pages/Risers';
import CoinDetails from './pages/CoinDetails';
import Login from './pages/Login';
import SIgnup from './pages/SIgnup';
import Favorite from './pages/Favorite';
import PortfolioCoinDetails from './pages/PortfolioCoinDetails';
import Faqs from './pages/Faqs';
import NewTokenList from './pages/NewTokenList';
import Calculator from './pages/Calculator';


function App() {
  return (
    <div className={`App`}>
      
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/risers" element={<Risers />} />
          <Route exact path="/fallers" element={<Fallers />} />
          <Route exact path="/coin/:id" element={<CoinDetails />} />
          <Route exact path="/favorites" element={<Favorite />} />
          <Route exact path="/favorites/:id" element={<PortfolioCoinDetails />} />
          <Route exact path="/faqs" element={<Faqs />} />
          <Route exact path="/request-form/tokens/new" element={<NewTokenList />} />
          <Route exact path="/calculator" element={<Calculator />} />

          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<SIgnup />} />
        </Routes>
      
    </div>
  );
}

export default App;