import React, { useContext, useEffect, useRef, useState } from 'react'
import { GlobalContext } from '../context/GlobalContext';
import { Link, useNavigate } from 'react-router-dom';
import logoImg from '../img/logo-udf.png';
import { AuthContext } from '../providers/AuthProvider';
import localStorage from 'local-storage';

export default function Header() {
    const { handleCookies, user, loadingUserData } = useContext(AuthContext);
    const { toggleTheme } = useContext(GlobalContext);
    const menuRef = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigator = useNavigate();



    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };


    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        if (isMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuOpen]);


    return (
        <header>
            <div class="primary-background">
                <div class="darken-background">
                    <div class="row container">
                        <div id="total-cryptocurrencies" class="col s12 m4 left-align">
                            <b>7273</b> cryptocurrencies
                        </div>
                        <div class="col s12 m4 center-align">
                            Total Market Cap <b>$3,272.41B</b>
                        </div>
                        <div id="total-volume" class="col s12 m4 right-align">
                            24h Total Volume <b>$903.70B</b>
                        </div>
                    </div>
                </div>
            </div>

            <nav class="primary-background" role="navigation">
                <div class="nav-wrapper container">

                    <Link to="/" class="brand-logo">
                        <img src={logoImg} alt='logo' />
                    </Link>
                    <ul class="right">
                        <li>
                            <select id="search-coins" tabindex="-1" class="select2-hidden-accessible" aria-hidden="true"></select>
                            <span class="select2 select2-container select2-container--default" dir="ltr" style={{ "width": "300px" }}>
                                <span class="selection">
                                    <span class="select2-selection select2-selection--single select2-selection-search-coins" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-labelledby="select2-search-coins-container">
                                        <span class="select2-selection__rendered" id="select2-search-coins-container">

                                        </span>
                                        <span class="select2-selection__arrow" role="presentation">
                                            <b role="presentation"></b>
                                        </span>
                                    </span>
                                </span>
                                <span class="dropdown-wrapper" aria-hidden="true"></span>
                            </span>
                        </li>
                        <li><a href='#sec' id="search-coins-button"><i class="material-icons">search</i></a></li>
                        <li onClick={()=>toggleTheme()}><a href="#sec"><i class="material-icons">brightness_medium</i></a></li>
                        <li><button type='button' onClick={() => toggleMenu()}><i class="material-icons">menu</i></button></li>
                    </ul>
                </div>
            </nav>


            <ul class="dropdown-content">
                <li>
                    <a href="#sec" class="language-code">
                        <i class="material-icons">settings</i> Settings
                    </a>
                </li>
            </ul>

            {isMenuOpen &&
                <ul id="side-menu" ref={menuRef} class="side-nav" style={{ "transform": "translateX(0px)" }}>
                    <li><a href="#sec" class="subheader">BlockStar</a></li>
                    <li><Link to="/" class="waves-effect"><i class="material-icons">public</i>Home</Link></li>
                    <li><Link to="/risers" class="waves-effect"><i class="material-icons">trending_up</i>Top Gainers</Link></li>
                    <li><Link to="/fallers" class="waves-effect"><i class="material-icons">trending_down</i>Top Losers</Link></li>
                    <li><Link to="/favorites" class="waves-effect"><i class="material-icons">star_border</i>Favorite Coins</Link></li>
                    <li><Link to="/calculator" class="waves-effect"><i class="material-icons">compare_arrows</i>Cryptocurrency Calculator</Link></li>
                    <li><Link to="/faqs" class="waves-effect"><i class="material-icons">help_outline</i>FAQ</Link></li>
                    {!loadingUserData && user ? (
                        <li><a href="#sec" onClick={() => {
                            handleCookies();
                            localStorage.remove('theme');
                            navigator('/login');


                        }} class="waves-effect"><i class="material-icons">lock_open</i>Log Out</a></li>
                    ) : (
                        <li><Link to="/login" class="waves-effect"><i class="material-icons">lock_open</i>Log in</Link></li>

                    )
                    }

                    <li class="btn-side-menu-languages-container">
                        <a href="#sec" id="btn-side-menu-languages" data-activates="side-menu-languages" class="waves-effect">
                            <i class="material-icons">translate</i>
                            <span>
                                <img class="right mn-lang-img" src="assets/images/flags/us.png" alt='falg' />
                                Language
                            </span>
                        </a>
                    </li>

                    <li class="btn-side-menu-currency-container">
                        <a href="#sec" id="btn-side-menu-currency" data-activates="side-menu-currency" class="waves-effect">
                            <i class="material-icons">attach_money</i>
                            <span>
                                <span class="right mn-currency-val">USD</span>
                                Display currency
                            </span>
                        </a>
                    </li>

                    <li class="btn-side-menu-currency-container"><a href="#sec" id="theme-change-button" class="waves-effect" onClick={toggleTheme}><i class="material-icons">brightness_medium</i>Day / night mode</a></li>

                </ul>
            }
        </header>
    )
}
