import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { backend_api } from "../helper/constant";
import Layout from "../components/Layout";
import { AuthContext } from "../providers/AuthProvider";
import FavoriteTable from "../components/FavoriteTable";

const Favorite = () => {
    const [coins, setCoins] = useState([]);
    const [loading, setLoading] = useState(false);
    const { handleGetCookies } = useContext(AuthContext);
    const [refersh, setRefersh] = useState('1');
    const [portfolioData,setPortfolioData] = useState({})


    useEffect(() => {
        const fetchCoins = async () => {
            setLoading(true);
            try {
                let userCookies = handleGetCookies('blockstarUser');
                const formData = new FormData();
                formData.append('token', userCookies);
                const response = await backend_api.post(`get-favorite.php` , formData);
                if (response && response.data && response.data.data) {
                    setCoins(response.data.data.fav);
                    setPortfolioData(response.data.data.data)
                }
            } catch (error) {
                console.error('Error fetching coins:', error);
            }
            setLoading(false);
        };

        fetchCoins();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refersh]);

    return (
        <Layout>
            <section id="content" class="container">
                <section id="news-ticker" className="mt-5">
                    {/* <Filters /> */}
                    <FavoriteTable coins={coins} loading={loading} type={1} setRefersh={setRefersh} portfolioData={portfolioData} />
                    <Outlet />
                </section>
            </section>
        </Layout>
    );
};

export default Favorite;
