import React, { useEffect, useRef } from 'react';

const TwitterTimelineEmbed = ({ username }) => {
  const timelineRef = useRef(null);

  useEffect(() => {
    const loadTwitterScript = () => {
      if (!window.twttr) {
        const script = document.createElement('script');
        script.src = 'https://platform.twitter.com/widgets.js';
        script.async = true;
        script.onload = createTimeline;
        document.body.appendChild(script);
      } else {
        createTimeline();
      }
    };

    const createTimeline = () => {
      window.twttr.widgets.createTimeline(
        {
          sourceType: 'profile',
          screenName: username,
        },
        timelineRef.current,
        {
          height: '600',
        }
      );
    };

    loadTwitterScript();
  }, [username]);

  return (
    <div ref={timelineRef} />
  );
};

export default TwitterTimelineEmbed;
