import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { backend_api } from '../helper/constant';
import { AuthContext } from '../providers/AuthProvider';

export default function SIgnup() {
    const { handleCookies, setUser } = useContext(AuthContext);
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [blockstarLogin, setBlockstarLogin] = useState(false);
    const count = useRef(0);
    const [queryParameters] = useSearchParams();


    useEffect(() => {
        async function fetch() {
            if (queryParameters.get('code') && count.current === 0) {
                count.current = count.current + 1;
                setBlockstarLogin(true)
                const formData = new FormData();
                formData.append('token', queryParameters.get('code'));

                const response = await backend_api.post('/blockstar-login.php', formData);
                if (response && response.data && response.data.status === 'success') {
                    handleCookies('add', response.data.data.token);
                    setUser(response.data.data.data);
                    navigate('/');
                }
                else {
                    toast.error(response.data.message)
                    setLoading(false);
                    navigate('/login');
                }

                setBlockstarLogin(false);

            }
        }

        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParameters])


    const submitForm = async () => {
        setLoading(true);
        try {
            if (firstname && lastname && email && password) {
                const formData = new FormData();
                formData.append('email', email);
                formData.append('password', password);
                formData.append('first_name', firstname);
                formData.append('last_name', lastname);
                const response = await backend_api.post('/register.php', formData);

                if (response && response.data && response.data.status === 'success') {
                    toast.success(response.data.message);
                    setLoading(false);
                }
                else {
                    toast.error(response.data.message);
                    setLoading(false);
                }
            }
            else {
                toast.error('Please enter valid data');
                setLoading(false);
            }
        }
        catch (err) {
            toast.error(err.message);
            console.log(err.message);
            setLoading(false);
        }
    };


    return (
        <section class="bg-dark">
            <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div class="w-full bg-dark-100 rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl text-white">
                            Create an account
                        </h1>
                        <div class="flex flex-col space-y-5">

                            <div class="flex flex-col space-y-4">
                                <a disabled={blockstarLogin} href="https://blockstar.social/oauth?app_id=517146f1f20f5deac8d8"
                                    class="flex items-center justify-center px-4 py-2 space-x-2 transition-colors duration-300 border border-gray-800 rounded-md group hover:bg-gray-800 focus:outline-none"
                                >
                                    <span>
                                        <img alt="blockstar" src="https://alchemy.mypinata.cloud/ipfs/QmaTpWAbw33A1WPhPmN4wHA9DDpjMMn2w6WVYdaBFumVNe" style={{ "width": "35px", "height": "35px" }} class="w-10" />
                                    </span>
                                    <span class="w-5/6">{blockstarLogin ? 'Loading...' : 'Login with BlockStar'}</span>
                                </a>
                            </div>
                            <span class="flex items-center justify-center space-x-2">
                                <span class="h-px bg-gray-400 w-14"></span>
                                <span class="font-normal text-gray-500">or signup with</span>
                                <span class="h-px bg-gray-400 w-14"></span>
                            </span>
                        </div>
                        <form class="space-y-4 md:space-y-6">
                            <div>
                                <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your First Name</label>
                                <input onChange={(e) => setFirstName(e.target.value)} value={firstname} type="text" name="first_name" id="first_name" class="text-white bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter first name" required={true} />
                            </div>
                            <div>
                                <label for="last_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Last Name</label>
                                <input onChange={(e) => setLastName(e.target.value)} value={lastname} type="text" name="last_name" id="last_name" class="text-white bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter last name" required={true} />
                            </div>
                            <div>
                                <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                                <input onChange={(e) => setEmail(e.target.value)} value={email} type="email" name="email" id="email" class="text-white bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="" />
                            </div>
                            <div>
                                <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                <input onChange={(e) => setPassword(e.target.value)} value={password} type="password" name="password" id="password" placeholder="••••••••" class="text-white bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                            </div>
                            <button disabled={loading} onClick={() => submitForm()} type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 w-full">
                                { loading ? 'Loading...' :  'Create Account'}
                            </button>

                        </form>

                        <p class="text-sm font-light">
                            Already have an account?
                            <Link to="/login" class="font-medium text-primary-600 hover:underline dark:text-primary-500">Login here</Link>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}
