import React, { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { backend_api } from '../helper/constant';
import { AuthContext } from '../providers/AuthProvider';
import { toast } from 'react-toastify';

export default function AddCoin({ show, setShow, coins, selectedCoin , setRefersh }) {
    const { handleGetCookies } = useContext(AuthContext);
    const [selectId, setSelecteId] = useState(0);
    const [quantity, setQuantity] = useState('');
    const [price, setPrice] = useState('');
    const [totalSpent, setTotalSpent] = useState('');
    const [buyTime, setBuyTime] = useState(new Date());


    useEffect(() => {
        if (selectedCoin) {
            setSelecteId(selectedCoin.id);
        }
    }, [selectedCoin]);

    const handleSetLivePrice = () => {
        const selectedCoinDetails = coins.find(coin => coin.id === selectId);
        if (selectedCoinDetails) {
            setPrice(selectedCoinDetails.price);
            if (quantity) {
                setTotalSpent(quantity * selectedCoinDetails.price);
            }
        }
    };

    const handleQuantityChange = (e) => {
        const qty = e.target.value;
        setQuantity(qty);
        if (price) {
            setTotalSpent(qty * price);
        }
    };

    const handlePriceChange = (e) => {
        const prc = e.target.value;
        setPrice(prc);
        if (quantity) {
            setTotalSpent(quantity * prc);
        }
    };

    const handleDateChange = (date) => {
        setBuyTime(date);
    };

    const handleSubmit = async () => {
        try {
            let userCookies = handleGetCookies('blockstarUser');
            const formData = new FormData();
            formData.append('token', userCookies);
            formData.append('f_id', selectedCoin?.fav_id);
            formData.append('qty', quantity);
            formData.append('price', price);
            formData.append('buy_time', parseInt(buyTime.getTime() / 1000));
            const response = await backend_api.post(`add-portfolio.php`, formData);
            if (response && response.data && response.data.status === 'success') {
                toast.success(response.data.message)
                setRefersh(Math.random());
                setShow(false)
            }
            else {
                toast.error(response.data.message)
            }

        } catch (error) {
            console.error('Error fetching coins:', error);
            toast.error(error.message);
        }
    }

    return (
        <div id="modal" className={`inset-0 bg-opacity-50 ${show ? 'flex fixed' : 'd-none'} items-center justify-center`}>
            <div className="rounded-lg shadow-lg w-full md:w-1/2 lg:w-1/3 bg-dark">
                <div className="flex justify-between items-center p-4 border-b">
                    <h2 className="text-md font-bold">Add Transaction</h2>
                    <button type='button' onClick={() => setShow(false)} className="text-gray-500 hover:text-gray-700">
                        <i className="fas fa-times"></i>
                    </button>
                </div>
                <div className="p-4">
                    <form id="add-coin-form" className="max-w-md mx-auto">
                        <div className="relative z-0 w-full mb-5 group">
                            <label htmlFor="coins" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select coin</label>
                            <select
                                id="coins"
                                name="coin"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={(e) => setSelecteId(e.target.value)}
                            >
                                {coins && coins.map((item, key) => (
                                    <option selected={parseInt(item.id) === parseInt(selectId)} key={key} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="grid md:grid-cols-2 md:gap-6">
                            <div className="relative z-0 w-full mb-5 group">
                                <input
                                    type="text"
                                    name="quantity"
                                    id="quantity"
                                    value={quantity}
                                    onChange={handleQuantityChange}
                                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    placeholder=" "
                                    required=""
                                />
                                <label
                                    htmlFor="quantity"
                                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 active"
                                >
                                    Quantity
                                </label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                                <input
                                    type="text"
                                    name="price"
                                    id="price"
                                    value={price}
                                    onChange={handlePriceChange}
                                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    placeholder=" "
                                    required=""
                                />
                                <label
                                    htmlFor="price"
                                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 active"
                                >
                                    Price per coin
                                </label>
                                <button type="button" onClick={handleSetLivePrice} className="text-blue-600 hover:text-blue-800 text-sm mt-2">Set Live Price</button>
                            </div>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input
                                type="text"
                                name="total_spent"
                                id="total_spent"
                                value={totalSpent}
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                readOnly
                            />
                            <label
                                htmlFor="total_spent"
                                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 active"
                            >
                                Total spent
                            </label>
                        </div>
                        <div className="relative max-w-sm mt-5">
                            <label htmlFor="buy_time" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Date & Time</label>
                            <DatePicker
                                selected={buyTime}
                                onChange={handleDateChange}
                                showTimeSelect
                                dateFormat="Pp"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderText="Select date"
                            />
                        </div>
                        <button
                            type="button"
                            onClick={() => handleSubmit()}
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
