import React, { useContext, useEffect, useRef, useState } from "react";
import TableComponent from "../components/TableComponent";
import Layout from "../components/Layout";
import { AuthContext } from "../providers/AuthProvider";
import { backend_api } from "../helper/constant";
import Typed from 'typed.js';

const Home = () => {
  const { handleGetCookies } = useContext(AuthContext);
  const [news, setNews] = useState([]);
  const [isFeatured, setIsFeatured] = useState(false)
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);
  const typedRef = useRef(null);
  const typedElementRef = useRef(null);


  useEffect(() => {
    const fetchNews = async () => {
      try {
        let userCookies = handleGetCookies('blockstarUser');
        const formData = new FormData();
        formData.append('token', userCookies);
        const response = await backend_api.get('get-news-feed.php', { data: formData });
        if (response && response.data && response.data.data) {
          setNews(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNews();
  }, [handleGetCookies]);


  useEffect(() => {
    const displayNews = (index) => {
      const newsItem = news[index];
      const fullText = `${newsItem.title} <a href="${newsItem.url}" target="_blank">Read More</a>`;

      if (typedRef.current) {
        typedRef.current.destroy(); // Destroy previous instance
      }

      typedRef.current = new Typed(typedElementRef.current, {
        strings: [fullText],
        typeSpeed: 50,
        backSpeed: 25,
        backDelay: 2000,
        loop: false,
        smartBackspace: true,
        showCursor: true,
        cursorChar: '|',
        onComplete: () => {
          document.querySelector('.typed-cursor').style.display = 'none';
        }
      });
    };

    if (news && news.length > 0) {
      displayNews(currentNewsIndex);
    }

    const interval = setInterval(() => {
      setCurrentNewsIndex((prevIndex) => (prevIndex + 1) % news.length);
    }, 10000);

    return () => clearInterval(interval);
  }, [currentNewsIndex,news]);

  return (
    <Layout>
      <section id="content" className="container">
        <section id="news-ticker">
          <div className="row">
            <div className="col s12 m4 l2">
              <div className="primary-background center">
                Breaking news
              </div>
            </div>
            <div className="col s12 m8 l10">

              <div id="news-container">
                <span ref={typedElementRef}></span>
              </div>

            </div>
          </div>
        </section>
        <section id="news-ticker" className="mt-5">
          <div class="row">
            <div class="col s12 center-align">
              <span class="switch">
                <label>
                  <input id="switch-featured" type="checkbox" onChange={() => setIsFeatured(!isFeatured)} checked={isFeatured} />
                  <span class="lever"></span>
                  Featured
                </label>
              </span>
            </div>
          </div>
          <TableComponent isFeatured={isFeatured} />
        </section>
      </section>
    </Layout>
  );
};

export default Home;
