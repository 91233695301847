import React, { useState } from 'react';
import Layout from '../components/Layout';
import { toast } from 'react-toastify';

export default function NewTokenList() {
    const [formData, setFormData] = useState({
        coin_name: '',
        coin_symbol: '',
        message: '',
        website_url: '',
        whitepaper_link: '',
        contract_address: '',
        contract_decimal_places: '',
        total_supply: '',
        blockstar_profile_link: '',
        twitter_profile_link: '',
        telegram_channel_link: '',
        facebook_profile_link: '',
        youtube_channel_link: '',
        medium_url: '',
        terms: false,
        token_image: null,
    });

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : type === 'file' ? files[0] : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.terms) {
            toast.error('You must agree to the terms and conditions to proceed.');
            return;
        }

        if (!formData.coin_name || formData.coin_symbol || !formData.contract_address || !formData.contract_decimal_places || !formData.total_supply) {
            toast.error('Please enter correct details');
            return;
        }

        await new Promise(resolve => setTimeout(resolve, 3000));
        toast.success('Successfully add coin')
    };

    return (
        <Layout>
            <div className="container mt-5">
                <div className="mx-auto">
                    <div>
                        <h2 className="font-semibold text-xl">Responsive Form</h2>
                        <p className="mb-6">New Token Listing Request Form</p>

                        <form className="bg-theme-dark rounded shadow-lg p-4 px-4 md:p-8 mb-6" onSubmit={handleSubmit}>
                            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">

                                <div>
                                    <p className="font-medium text-lg">1. Basic Coin/Token Information</p>
                                </div>
                                <div className="lg:col-span-2">
                                    <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                                        <div className="md:col-span-5">
                                            <label htmlFor="coin_name">Coin/Token Name<span className='text-red-500'>*</span></label>
                                            <input type="text" name="coin_name" id="coin_name" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.coin_name} onChange={handleChange} />
                                        </div>
                                        <div className="md:col-span-5">
                                            <label htmlFor="coin_symbol">Coin/Token Symbol<span className='text-red-500'>*</span></label>
                                            <input type="text" name="coin_symbol" id="coin_symbol" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.coin_symbol} onChange={handleChange} />
                                        </div>
                                        <div className="md:col-span-5">
                                            <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your message</label>
                                            <textarea id="message" name="message" rows="5" className="border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:border-blue-500 dark:focus:ring-blue-500 dark:placeholder-gray-400 dark:text-white focus:border-blue-500 focus:ring-blue-500 rounded-lg text-gray-900 text-sm" placeholder="Leave a comment..." value={formData.message} onChange={handleChange}></textarea>
                                        </div>
                                        <div className="md:col-span-2">
                                            <label htmlFor="website_url">Website URL</label>
                                            <input type="text" name="website_url" id="website_url" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.website_url} onChange={handleChange} />
                                        </div>
                                        <div className="md:col-span-2">
                                            <label htmlFor="whitepaper_link">Whitepaper Link</label>
                                            <input type="text" name="whitepaper_link" id="whitepaper_link" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.whitepaper_link} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>

                                <div className="lg:col-span-3">
                                    <hr className="my-4 border-gray-300" />
                                </div>

                                <div>
                                    <p className="font-medium text-lg">2. Contract Information</p>
                                </div>
                                <div className="lg:col-span-2">
                                    <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                                        <div className="md:col-span-5">
                                            <label htmlFor="contract_address">Contract Address<span className='text-red-500'>*</span></label>
                                            <input type="text" name="contract_address" id="contract_address" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.contract_address} onChange={handleChange} />
                                        </div>
                                        <div className="md:col-span-5">
                                            <label htmlFor="contract_decimal_places">Contract Decimal Places<span className='text-red-500'>*</span></label>
                                            <input type="text" name="contract_decimal_places" id="contract_decimal_places" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.contract_decimal_places} onChange={handleChange} />
                                        </div>
                                        <div className="md:col-span-5">
                                            <label htmlFor="total_supply">Total Supply<span className='text-red-500'>*</span></label>
                                            <input type="text" name="total_supply" id="total_supply" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.total_supply} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>

                                <div className="lg:col-span-3">
                                    <hr className="my-4 border-gray-300" />
                                </div>

                                <div>
                                    <p className="font-medium text-lg">3. Community Information</p>
                                </div>
                                <div className="lg:col-span-2">
                                    <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                                        <div className="md:col-span-2">
                                            <label htmlFor="blockstar_profile_link">BlockStar Profile Link</label>
                                            <input type="text" name="blockstar_profile_link" id="blockstar_profile_link" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.blockstar_profile_link} onChange={handleChange} />
                                        </div>
                                        <div className="md:col-span-2">
                                            <label htmlFor="twitter_profile_link">Twitter Profile Link</label>
                                            <input type="text" name="twitter_profile_link" id="twitter_profile_link" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.twitter_profile_link} onChange={handleChange} />
                                        </div>
                                        <div className="md:col-span-2">
                                            <label htmlFor="telegram_channel_link">Telegram Channel Link</label>
                                            <input type="text" name="telegram_channel_link" id="telegram_channel_link" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.telegram_channel_link} onChange={handleChange} />
                                        </div>
                                        <div className="md:col-span-2">
                                            <label htmlFor="facebook_profile_link">Facebook Profile Link</label>
                                            <input type="text" name="facebook_profile_link" id="facebook_profile_link" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.facebook_profile_link} onChange={handleChange} />
                                        </div>
                                        <div className="md:col-span-2">
                                            <label htmlFor="youtube_channel_link">YouTube Channel Link</label>
                                            <input type="text" name="youtube_channel_link" id="youtube_channel_link" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.youtube_channel_link} onChange={handleChange} />
                                        </div>
                                        <div className="md:col-span-2">
                                            <label htmlFor="medium_url">Medium URL</label>
                                            <input type="text" name="medium_url" id="medium_url" className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={formData.medium_url} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <p className="font-medium text-lg">8. Attachments</p>
                                </div>
                                <div className="lg:col-span-2">
                                    <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                                        <div className="md:col-span-2">
                                            <label htmlFor="token_image">Token Image<span className='text-red-500'>*</span></label>
                                            <input className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="token_image_help" id="token_image" name="token_image" type="file" onChange={handleChange} />
                                            <div className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="token_image_help">Please upload attachment to new logo (PNG/JPG/GIF/WEBP only). Preferably transparent background, 200px x 200px</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:col-span-3">
                                <hr className="my-4 border-gray-300" />
                            </div>

                            <div className="flex justify-center items-center mb-4">
                                <input
                                    type="checkbox"
                                    id="terms"
                                    name="terms"
                                    className="mr-2"
                                    checked={formData.terms}
                                    onChange={handleChange}
                                />
                                <label htmlFor="terms" className="text-gray-700">I agree to the terms and conditions</label>
                            </div>
                            <div className="flex justify-center items-center mb-4">
                                <button
                                    type="submit"
                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
