import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import RiseFallTable from "../components/RiseFallTable";
import { api, backend_api } from "../helper/constant";
import Layout from "../components/Layout";

const Risers = () => {
  const [coins, setCoins] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const fetchCoins = async () => {
      setLoading(true);
      try {
        const response = await backend_api.get(`get-coin.php?limit=30&sort_column=change_pct&sort_order=DESC`);
        if (response && response.data && response.data.data ) {
          setCoins(response.data.data.data);
        }
      } catch (error) {
        console.error('Error fetching coins:', error);
      }
      setLoading(false);
    };

    fetchCoins();
  }, []);

  return (
    <Layout>
      <section id="content" class="container">
        <section id="news-ticker" className="mt-5">
          {/* <Filters /> */}
          <RiseFallTable coins={coins} loading={loading} type={1} />
          <Outlet />
        </section>
      </section>
    </Layout>
  );
};

export default Risers;
