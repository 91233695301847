import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { backend_api } from '../helper/constant';
import { AuthContext } from '../providers/AuthProvider';


export default function Login() {
    const { handleCookies, setUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [blockstarLogin, setBlockstarLogin] = useState(false);
    const count = useRef(0);
    const [queryParameters] = useSearchParams();

    const submitForm = async () => {
        setLoading(true);
        try {
            if (email && password) {
                const formData = new FormData();
                formData.append('email', email);
                formData.append('password', password);
                const response = await backend_api.post('/login.php', formData);

                if (response && response.data && response.data.status === 'success') {
                    if (response.data.data && response.data.data.token) {
                        handleCookies('add', response.data.data.token);
                        setUser(response.data.data.data);
                        setLoading(false);
                        navigate('/')
                    }
                    else {
                        setLoading(false);
                    }
                }
                else {

                    toast.error(response.data.message)
                    setLoading(false);

                }
            }
            else {
                toast.error('Please enter valid data');
                setLoading(false);
            }
        }
        catch (err) {
            toast.error(err.message);
            console.log(err.message);
            setLoading(false);
        }
    };


    useEffect(() => {
        async function fetch() {
            if (queryParameters.get('code') && count.current === 0) {
                count.current = count.current + 1;
                setBlockstarLogin(true)
                const formData = new FormData();
                formData.append('token', queryParameters.get('code'));

                const response = await backend_api.post('/blockstar-login.php', formData);
                if (response && response.data && response.data.status === 'success') {
                    handleCookies('add', response.data.data.token);
                    setUser(response.data.data.data);
                    navigate('/');
                }
                else {
                    toast.error(response.data.message)
                    setLoading(false);
                    navigate('/login');
                }

                setBlockstarLogin(false);

            }
        }

        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParameters])

    return (
        <section class="bg-dark">
            <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div class="w-full bg-dark-100 rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 class="text-xl font-bold leading-tight tracking-tight text-white">
                            Sign in to your account
                        </h1>
                        <form class="space-y-4 md:space-y-6">
                            <div>
                                <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white bord">Your email</label>
                                <input onChange={(e) => setEmail(e.target.value)} value={email} type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" />
                            </div>
                            <div>
                                <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                <input onChange={(e) => setPassword(e.target.value)} value={password} type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                            </div>
                            <button onClick={() => submitForm()} type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 w-full">
                                {loading ? 'Loading...' : 'Sign In'}
                            </button>
                            <p class="text-sm font-light">
                                Don’t have an account yet?
                                <Link to="/signup" class="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</Link>
                            </p>
                        </form>
                        <div class="flex flex-col space-y-5">
                            <span class="flex items-center justify-center space-x-2">
                                <span class="h-px bg-gray-400 w-14"></span>
                                <span class="font-normal text-gray-500">or login with</span>
                                <span class="h-px bg-gray-400 w-14"></span>
                            </span>
                            <div class="flex flex-col space-y-4">
                                <a disabled={blockstarLogin} href="https://blockstar.social/oauth?app_id=517146f1f20f5deac8d8"
                                    class="flex items-center justify-center px-4 py-2 space-x-2 transition-colors duration-300 border border-gray-800 rounded-md group hover:bg-gray-800 focus:outline-none"
                                >
                                    <span>
                                        <img alt="blockstar" src="https://alchemy.mypinata.cloud/ipfs/QmaTpWAbw33A1WPhPmN4wHA9DDpjMMn2w6WVYdaBFumVNe" style={{ "width": "35px", "height": "35px" }} class="w-10" />
                                    </span>
                                    <span class="w-5/6">{blockstarLogin ? 'Loading...' : 'Login with BlockStar'}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
