import Axios from 'axios';

export const BACKEND_API = "https://markets.blockstar.site/api/";
// export const BACKEND_NEW_API = "http://localhost/blockstar-markets-backend/api/";
export const BACKEND_NEW_API = "https://marketsbackend.blockstar.site/api/";

export const api = Axios.create({
    baseURL: BACKEND_API,
    headers: {
        'Content-Type': 'application/json',
    },
    // Add other default settings here if needed
});

export const backend_api = Axios.create({
    baseURL: BACKEND_NEW_API
    // Add other default settings here if needed
});


export const formatPrice = (num, decimals = 7) => {
    if (num >= 1) {
        return new Intl.NumberFormat('en-US', { maximumSignificantDigits: decimals }).format(num);
    }
    else {
        return new Intl.NumberFormat('en-US', { maximumSignificantDigits: 4 }).format(num);
    }
}



export function convertToInternationalCurrencySystem (labelValue) {

    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
    // Three Zeroes for Thousands
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

    : Math.abs(Number(labelValue));

}


export const formatDateWithZone = (unixTime) => {
    console.log(unixTime)
    try {
      let dateString = new Date(parseInt(unixTime * 1000)).toString();
      console.log(dateString)
      let startIndex = dateString.indexOf("GMT");
      let modifyDate = dateString.substring(0, startIndex);
      console.log(modifyDate)
      return modifyDate;
    }
    catch (err) {
      console.log(err.message);
    }
  
  }
