import React, { useContext, useEffect, useState } from "react";
import { backend_api, formatPrice } from "../helper/constant";
import { SaveBtn } from "./SaveBtn";
import { AuthContext } from "../providers/AuthProvider";

const RiseFallTable = ({ coins, loading, type }) => {
    const { handleGetCookies } = useContext(AuthContext);
    const [refersh, setRefersh] = useState('1');
    const [favData,setFavData] = useState([])

    useEffect(() => {
        const fetchFavrites = async () => {
            try {
                let userCookies = handleGetCookies('blockstarUser');
                const formData = new FormData();
                formData.append('token', userCookies);
                const response = await backend_api.post(`get-favorite.php`, formData);
                if (response && response.data && response.data.data) {
                    setFavData(response.data.data.fav)
                }
            } catch (error) {
                console.error('Error fetching coins:', error);
            }
        };
        fetchFavrites();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refersh]);

    return (
        <>
            <div className="row">
                <div className="col s12">
                    <table id="coins-list" className="bordered highlight vertical-responsive-table">
                        <thead>
                            <tr>
                                <th>
                                    <a href="#sec">Cryptocurrency</a>
                                </th>
                                <th className="right-align">
                                    <a href="#sec">Price</a>
                                </th>
                                <th className="right-align">
                                    <a href="#sec">24h % Change</a>
                                </th>
                                <th className="right-align">
                                    <a href="#sec">Volume</a>
                                </th>
                                <th className="right-align">
                                    <a href="#sec">
                                        <i className="material-icons">arrow_drop_down</i>
                                        Market cap
                                    </a>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading && coins && coins.map((coin, index) => (
                                <tr key={index} className={`coin-row coin-row-${coin.symbol}`} data-symbol={`${coin.symbol}`}>
                                    <td className="flex" data-title="Cryptocurrency">
                                        <span className="coin-number">{index + 1}</span>
                                        <a href="#sec" className="flex">
                                            <SaveBtn data={coin} favData={favData} setRefersh={setRefersh} />
                                            <img src={`assets/images/coins/thumb30/${coin.logo}`} alt={coin.name} title={coin.name} />
                                        </a>
                                        <span><a href={`/coin/${coin.symbol}`}>{coin.name}</a></span>
                                    </td>
                                    <td className="right-align" data-title="Price">
                                        <sup>$</sup><span className="coin-price-value">{formatPrice(coin.price, 8)}</span>
                                    </td>
                                    <td className="right-align" data-title="24h % Change">
                                        <span className={`coin-change_pct-value ${type === 1 ? 'change-up' : 'change-down'}`}>{coin.change_pct}%</span>
                                    </td>
                                    <td className="right-align" data-title="Volume">
                                        <span className="coin-volume-value">{coin.volume}</span> {coin.symbol}
                                    </td>
                                    <td className="right-align" data-title="Market cap">
                                        <sup>$</sup><span className="coin-market_cap-value">{formatPrice(coin.market_cap, 12)}</span>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
            </div>
            {loading &&
                <div class="row">
                    <div class="col s12 center-align">
                        <button type="button" class="waves-effect waves-light btn-large"><i class="material-icons left">cloud_download</i>Loading...</button>
                    </div>
                </div>
            }
        </>
    );
};

export default RiseFallTable;
