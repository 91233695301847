import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../providers/AuthProvider';
import { backend_api, convertToInternationalCurrencySystem, formatDateWithZone, formatPrice } from '../helper/constant';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Layout from '../components/Layout';
import EditCoin from '../components/EditCoin';

export default function PortfolioCoinDetails() {
    const { handleGetCookies } = useContext(AuthContext);
    const [favData, setFavData] = useState([]);
    const [coinData, setCoinData] = useState([]);
    const [portfolioData, setPortfolioData] = useState([]);
    const { id } = useParams();
    const navigator = useNavigate();
    const [refresh, setRefersh] = useState('1');
    const [selectedCoin, setSelectedCoin] = useState([]);
    const [show, setShow] = useState(false)

    useEffect(() => {
        const fetch = async () => {
            try {
                let userCookies = handleGetCookies('blockstarUser');
                const formData = new FormData();
                formData.append('token', userCookies);
                formData.append('coin_id', id);
                const response = await backend_api.post(`edit-portfolio.php`, formData);
                if (response && response.data && response.data.data && response.data.data.coin && response.data.data.portfolio_data && response.data.data.portfolio_data.length > 0) {
                    setFavData(response.data.data?.data)
                    setCoinData(response.data.data.coin)
                    setPortfolioData(response.data.data?.portfolio_data[0])
                }
                else {
                    toast.error('No data found');
                    navigator('/favorites')
                }
            } catch (error) {
                console.error('Error fetching coins:', error);
                toast.error('Something went wrong');
                navigator('/favorites')
            }
        };
        if (id) {
            fetch();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id , refresh]);


    const handleDelete = async (id) => {
        try {
            let userCookies = handleGetCookies('blockstarUser');
            const formData = new FormData();
            formData.append('token', userCookies);
            formData.append('id', id);
            formData.append('type', 'delete');
            const response = await backend_api.post(`update-portfolio.php`, formData);
            if (response && response.data && response.data.status === 'success') {
                toast.success(response.data.message)
                setRefersh(Math.random());
                setShow(false)
            }
            else {
                toast.error(response.data.message)
            }

        } catch (error) {
            console.error('Error fetching coins:', error);
            toast.error(error.message);
        }
    }


    return (
        <Layout>
            <section id="content" class="container">
                <section id="coin-header">
                    <div class="container mx-auto">
                        <div class="flex flex-wrap w-full items-center px-8 py-12 md:px-16 border-b">
                            <div class="flex flex-col flex-auto my-12 mx-8">
                                <div class="flex items-center space-x-2">
                                    <img src={`/assets/images/coins/${coinData?.logo}`} alt={coinData?.symbol} class="w-10 h-10" />
                                    <p class="font-medium text-md">{coinData?.name}</p>
                                    <p class="font-medium text-md tracking-wider">({coinData?.symbol})</p>
                                </div>
                                <div class="flex mt-4 space-x-2">
                                    <p class="font-mono text-3xl leading-none tracking-tight">${formatPrice(coinData && coinData.price ? coinData.price : 0)}</p>
                                    <div class="flex">
                                        {coinData && parseFloat(coinData.change_pct) < 0 ? (
                                            <svg class="shrink-0 fill-current text-green-500 mx-2 mb-px text-red-800" viewBox="0 0 24 24" width="24" height="24" stroke={coinData && parseFloat(coinData.change_pct) < 0 ? `#bb3232` : `#008000`} stroke-width="2" fill={coinData && parseFloat(coinData.change_pct) < 0 ? `#ff0000` : `#008000`} stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><polyline points="19 12 12 19 5 12"></polyline></svg>
                                        ) : (
                                            <svg class="shrink-0 fill-current text-green-500 mx-2 mb-px text-red-800" viewBox="0 0 24 24" width="24" height="24" stroke={coinData && parseFloat(coinData.change_pct) < 0 ? `#bb3232` : `#008000`} stroke-width="2" fill={coinData && parseFloat(coinData.change_pct) < 0 ? `#ff0000` : `#008000`} stroke-linecap="round" stroke-linejoin="round" ><line x1="12" y1="19" x2="12" y2="5"></line><polyline points="5 12 12 5 19 12"></polyline></svg>
                                        )}
                                        <p class={`font-mono font-medium text-md leading-none mb-px ${coinData && parseFloat(coinData.change_pct) < 0 ? 'text-red' : 'text-success'}`}>{parseFloat(coinData.change_pct)}%</p>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden sm:flex items-center my-12 mx-8 rounded-xl border">
                                <div class="p-4">
                                    <p class="text-sm font-medium leading-none">Holdings Value</p>
                                    <p class="mt-2 font-mono text-md leading-none">${convertToInternationalCurrencySystem(portfolioData && portfolioData.current_value ? portfolioData.current_value : 0)}</p>
                                </div>
                                <div class="border-l h-full"></div>
                                <div class="p-4">
                                    <p class="text-sm font-medium leading-none">Holdings</p>
                                    <p class="mt-2 font-mono text-md leading-none">{convertToInternationalCurrencySystem(portfolioData && portfolioData.total_qty ? portfolioData.total_qty : 0)} {coinData?.symbol}</p>
                                </div>
                                <div class="border-l h-full"></div>
                                <div class="p-4">
                                    <p class="text-sm font-medium leading-none">Total Cost</p>
                                    <p class="mt-2 font-mono text-md leading-none">${convertToInternationalCurrencySystem(portfolioData && portfolioData.total_spent_sum ? portfolioData.total_spent_sum : 0)}</p>
                                </div>
                                <div class="border-l h-full"></div>
                                <div class="p-4">
                                    <p class="text-sm font-medium leading-none">Average Net Cost</p>
                                    <p class={`mt-2 font-mono text-md leading-none`}>
                                        ${formatPrice(portfolioData && portfolioData.total_spent_sum && portfolioData.total_qty ? parseFloat(portfolioData.total_spent_sum) / parseFloat(portfolioData.total_qty) : 0, 5)}
                                    </p>
                                </div>
                                <div class="border-l h-full"></div>
                                <div class="p-4">
                                    <p class="text-sm font-medium leading-none">Total Profit / Loss</p>
                                    <p class={`mt-2 font-mono text-md leading-none ${portfolioData && portfolioData.current_value && portfolioData.total_spent_sum && parseFloat(portfolioData.total_spent_sum) > parseFloat(portfolioData.current_value) ? 'change-down' : 'change-up'}`}>
                                        ${formatPrice(parseFloat(portfolioData.current_value) - parseFloat(portfolioData.total_spent_sum), 5)}
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12">
                            <table id="coins-list" className="bordered highlight vertical-responsive-table">
                                <thead>
                                    <tr>
                                        <th>
                                            <a href="#sec">Type</a>
                                        </th>
                                        <th className="right-align">
                                            <a href="#sec">Price</a>
                                        </th>
                                        <th className="right-align">
                                            <a href="#sec">Quantity	</a>
                                        </th>
                                        <th className="right-align">
                                            <a href="#sec"> Date & Time</a>
                                        </th>
                                        <th className="right-align">
                                            <a href="#sec">
                                                Cost
                                            </a>
                                        </th>
                                        <th className="right-align">
                                            <a href="#sec">
                                                PNL
                                            </a>
                                        </th>
                                        <th className="right-align">
                                            <a href="#sec">
                                                Actions
                                            </a>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { favData && favData.map((items, index) => (
                                        <tr key={index} className={`coin-row`}>
                                            <td className="flex" data-title="Cryptocurrency">
                                                <span className="coin-number">Buy</span>
                                            </td>
                                            <td className="right-align" data-title="Price">
                                                <sup>$</sup><span className="coin-price-value">{formatPrice(items.price, 8)}</span>
                                            </td>
                                            <td className="right-align" data-title="24h % Change">
                                                <span className="coin-price-value">{formatPrice(items.qty, 8)}</span>
                                            </td>
                                            <td className="right-align" data-title="Volume">
                                                <span>{items.buy_time ? formatDateWithZone(parseInt(items.buy_time)) : ' - '}</span>
                                            </td>
                                            <td className="right-align" data-title="Market cap">
                                                <span className="coin-price-value">{items.total_spent ? formatPrice(items.total_spent) : '-'}</span>
                                            </td>
                                            <td className="right-align" data-title="Market cap">
                                                {items && items.current_value && items.total_spent ? (
                                                    parseFloat(items.current_value) - parseFloat(items.total_spent) < 0 ? (
                                                        <>
                                                            <p className="change-down">${formatPrice(parseFloat(items.current_value) - parseFloat(items.total_spent))}</p>
                                                        </>

                                                    ) :
                                                        parseFloat(items.current_value) - parseFloat(items.total_spent) > 0 ? (
                                                            <p className="change-up">${formatPrice(parseFloat(items.current_value) - parseFloat(items.total_spent))}</p>

                                                        ) : (
                                                            <>
                                                                <p className="change-down">$0</p>
                                                            </>

                                                        )) : (
                                                    <>
                                                        <p>-</p>
                                                    </>
                                                )}
                                            </td>
                                            <td className="right-align" data-title="Market cap">
                                                <button type="button" onClick={() => {
                                                    setShow(true)
                                                    setSelectedCoin(items)
                                                }} class="bg-blue-500 text-white px-3 py-1 mx-1 rounded hover:bg-blue-600">
                                                    <i class="fas fa-edit"></i>
                                                </button>
                                                <button onClick={()=>handleDelete(items.id)} type="button" class="bg-blue-500  text-white px-3 py-1 mx-1 rounded hover:bg-blue-600">
                                                    <i class="fas fa-remove text-white"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div >
                    </div >
                </section>
            </section>
            <EditCoin show={show} setShow={setShow} selectedCoin={selectedCoin} setRefersh={setRefersh} />
        </Layout>
    )
}
