import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api, backend_api, convertToInternationalCurrencySystem, formatPrice } from "../helper/constant";
import loadScript from 'load-script';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TwitterTimelineEmbed from "../components/TwitterTimelineEmbed";
import Pagination from "../components/Pagination";
import Layout from "../components/Layout";

export default function CoinDetails() {
  const [tabId, setTabId] = useState(1);
  const { id } = useParams();
  const [coins, setCoins] = useState([]);
  const [coinhistory, setCoinhistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchCoins = async () => {
      try {
        const response = await backend_api.post(`get-coin-details.php?symbol=${id}`);
        if (response && response.data) {
          setCoins(response.data.data);

        }
      } catch (error) {
        console.error('Error fetching coins:', error);
      }
    };
    if (id) {
      fetchCoins();
    }
  }, [id]);


  useEffect(() => {
    const fetchCoins = async () => {
      try {
        const response = await api.get(`history/${id}`);
        if (response && response.data && response.data.history) {
          setCoinhistory(response.data.history);
        }
      } catch (error) {
        console.error('Error fetching coins:', error);
      }
    };
    if (id) {
      fetchCoins();
    }
  }, [id]);


  useEffect(() => {
    if (coins) {
      // Script URLs
      const scriptSources = [
        `${process.env.PUBLIC_URL}/assets/vendor/numeral/numeral.min.js`,
        `${process.env.PUBLIC_URL}/assets/vendor/amstock/amcharts.js`,
        `${process.env.PUBLIC_URL}/assets/vendor/amstock/themes/dark.js`,
        `${process.env.PUBLIC_URL}/assets/vendor/amstock/serial.js`,
        `${process.env.PUBLIC_URL}/assets/vendor/amstock/amstock.js`,
        `${process.env.PUBLIC_URL}/assets/vendor/amstock-dataloader/dataloader.js`,
        `${process.env.PUBLIC_URL}/assets/vendor/amstock/plugins/export/export.min.js`,
        `${process.env.PUBLIC_URL}/assets/vendor/moment/moment.min.js`,
        `${process.env.PUBLIC_URL}/assets/vendor/moment/locales.min.js`,
        `${process.env.PUBLIC_URL}/assets/vendor/datatables/jquery.dataTables.min.js`,
        `${process.env.PUBLIC_URL}/assets/js/coin.js`,
        `${process.env.PUBLIC_URL}/assets/js/facebook.min.js`,
        `${process.env.PUBLIC_URL}/assets/js/twitter.min.js`,
      ];

      // Load scripts sequentially
      const loadScriptsSequentially = (sources, callback) => {
        if (sources.length === 0) {
          callback();
          return;
        }

        loadScript(sources[0], (err) => {
          if (err) {
            console.error(`Failed to load script: ${sources[0]}`, err);
          } else {
            loadScriptsSequentially(sources.slice(1), callback);
          }
        });
      };

      loadScriptsSequentially(scriptSources, () => {
        if (window.updateCoinData) {

          window.updateCoinData(coins);
        }
      });

      // Clean up function
      return () => {
        // Note: You can't easily remove dynamically loaded scripts, but you can manage their state if needed
      };
    }
  }, [coins]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(coinhistory.length / recordsPerPage);
  const currentData = coinhistory.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);


  return (
    <Layout>
      <section id="content" class="container">
        <section id="coin-header">
          <div class="container mx-auto">
            <div class="flex flex-wrap w-full items-center px-8 py-12 md:px-16 border-b">
              <div class="flex flex-col flex-auto my-12 mx-8">
                <div class="flex items-center space-x-2">
                  <img src={`/assets/images/coins/${coins?.logo}`} alt={coins?.symbol} class="w-10 h-10" />
                  <p class="font-medium text-md">{coins?.name}</p>
                  <p class="font-medium text-md tracking-wider">({coins?.symbol})</p>
                </div>
                <div class="flex mt-4 space-x-2">
                  <p class="font-mono text-3xl leading-none tracking-tight">${formatPrice(coins && coins.price ? coins.price : 0)}</p>
                  <div class="flex">
                    {coins && parseFloat(coins.change_pct) < 0 ? (
                      <svg class="shrink-0 fill-current text-green-500 mx-2 mb-px text-red-800" viewBox="0 0 24 24" width="24" height="24" stroke={coins && parseFloat(coins.change_pct) < 0 ? `#bb3232` : `#008000`} stroke-width="2" fill={coins && parseFloat(coins.change_pct) < 0 ? `#ff0000` : `#008000`} stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><polyline points="19 12 12 19 5 12"></polyline></svg>
                    ) : (
                      <svg class="shrink-0 fill-current text-green-500 mx-2 mb-px text-red-800" viewBox="0 0 24 24" width="24" height="24" stroke={coins && parseFloat(coins.change_pct) < 0 ? `#bb3232` : `#008000`} stroke-width="2" fill={coins && parseFloat(coins.change_pct) < 0 ? `#ff0000` : `#008000`} stroke-linecap="round" stroke-linejoin="round" ><line x1="12" y1="19" x2="12" y2="5"></line><polyline points="5 12 12 5 19 12"></polyline></svg>
                    )}
                    <p class={`font-mono font-medium text-md leading-none mb-px ${coins && parseFloat(coins.change_pct) < 0 ? 'text-red' : 'text-success'}`}>{parseFloat(coins.change_pct)}%</p>
                  </div>
                </div>
                <div class="flex mt-4 space-x-2">
                  <a href="https://dex.blockstar.site/" rel="noreferrer" class="green waves-effect waves-light btn" target="_blank"><i class="material-icons left">arrow_upward</i>Buy {id}</a>

                  <a href="https://dex.blockstar.site/" rel="noreferrer" class="red waves-effect waves-light btn" target="_blank"><i class="material-icons left">arrow_downward</i>Sell {id}</a>
                </div>
              </div>
              <div class="hidden sm:flex items-center my-12 mx-8 rounded-xl border">
                <div class="p-4">
                  <p class="text-sm font-medium leading-none">Market Cap</p>
                  <p class="mt-2 font-mono text-md leading-none">${convertToInternationalCurrencySystem(coins && coins.market_cap ? coins.market_cap : 0)}</p>
                </div>
                <div class="border-l h-full"></div>
                <div class="p-4">
                  <p class="text-sm font-medium leading-none">Volume</p>
                  <p class="mt-2 font-mono text-md leading-none">${convertToInternationalCurrencySystem(coins && coins.volume_ccy ? coins.volume_ccy : 0)}</p>
                </div>
                <div class="border-l h-full"></div>
                <div class="p-4">
                  <p class="text-sm font-medium leading-none">Supply</p>
                  <p class="mt-2 font-mono text-md leading-none">${convertToInternationalCurrencySystem(coins && coins.supply ? coins.supply : 0)}</p>
                </div>
                <div class="border-l h-full"></div>
                <div class="p-4">
                  <p class="text-sm font-medium leading-none">24h Change</p>
                  <p class={`mt-2 font-mono text-md leading-none ${coins && parseFloat(coins.change_pct) < 0 ? 'text-red' : 'text-success'}`}>${formatPrice(coins && coins.change ? coins.change : 0, 5)}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div class="row">
            <div class="col s12">
              <div id="coin-chart">
                <div id="coin-chart-preloader">
                  <div class="preloader-wrapper small active">
                    <div class="spinner-layer spinner-green-only">
                      <div class="circle-clipper left">
                        <div class="circle"></div>
                      </div>
                      <div class="gap-patch">
                        <div class="circle"></div>
                      </div>
                      <div class="circle-clipper right">
                        <div class="circle"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="coin-chart-comparison">
                  <select id="select-comparison" multiple="multiple"></select>
                </div>
                <div id="coin-chart-container"></div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div class="row">
            <div class="col s12 medium-vertical-margin">
              <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                <ul className="flex flex-wrap -mb-px">
                  <li className="me-2">
                    <button
                      type="button"
                      onClick={() => setTabId(1)}
                      className={`${tabId === 1
                        ? 'active border-b-2 border-blue-600 dark:border-blue-500 dark:text-blue-500 inline-block p-4 rounded-t-lg text-blue-600'
                        : 'border-b-2 dark:border-blue-500 dark:text-blue-500 inline-block p-4 rounded-t-lg'
                        }`}
                    >
                      Info
                    </button>
                  </li>
                  <li className="me-2">
                    <button
                      type="button"
                      onClick={() => setTabId(2)}
                      className={`${tabId === 2
                        ? 'active border-b-2 border-blue-600 dark:border-blue-500 dark:text-blue-500 inline-block p-4 rounded-t-lg text-blue-600'
                        : 'border-b-2 dark:border-blue-500 dark:text-blue-500 inline-block p-4 rounded-t-lg'
                        }`}
                    >
                      Key indicators
                    </button>
                  </li>
                  <li className="me-2">
                    <button
                      type="button"
                      onClick={() => setTabId(4)}
                      className={`${tabId === 4
                        ? 'active border-b-2 border-blue-600 dark:border-blue-500 dark:text-blue-500 inline-block p-4 rounded-t-lg text-blue-600'
                        : 'border-b-2 dark:border-blue-500 dark:text-blue-500 inline-block p-4 rounded-t-lg'
                        }`}
                    >
                      Social
                    </button>
                  </li>
                  <li className="me-2">
                    <button
                      type="button"
                      onClick={() => setTabId(5)}
                      className={`${tabId === 5
                        ? 'active border-b-2 border-blue-600 dark:border-blue-500 dark:text-blue-500 inline-block p-4 rounded-t-lg text-blue-600'
                        : 'border-b-2 dark:border-blue-500 dark:text-blue-500 inline-block p-4 rounded-t-lg'
                        }`}
                    >
                      Historical Data
                    </button>
                  </li>
                  <li className="me-2">
                    <button
                      type="button"
                      onClick={() => setTabId(6)}
                      className={`${tabId === 6
                        ? 'active border-b-2 border-blue-600 dark:border-blue-500 dark:text-blue-500 inline-block p-4 rounded-t-lg text-blue-600'
                        : 'border-b-2 dark:border-blue-500 dark:text-blue-500 inline-block p-4 rounded-t-lg'
                        }`}
                    >
                      Widget
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            {tabId === 1 &&
              <div id="info" class="active" >
                <div dangerouslySetInnerHTML={{ __html: coins?.description }} />
                <div dangerouslySetInnerHTML={{ __html: coins?.features }} />
                <div dangerouslySetInnerHTML={{ __html: coins?.technology }} />
                {coins && coins.website &&
                  <a href={coins.website} class="waves-effect waves-light btn" rel="noreferrer" target="_blank">
                    Visit website
                    <i class="material-icons right">open_in_new</i>
                  </a>
                }
              </div>
            }
            {tabId === 2 &&
              <div id="key_indicators" class="col s12">
                <table class="striped">
                  <tbody>
                    <tr>
                      <td>Market share</td>
                      <td class="right-align">{formatPrice(coins?.market_share, 3)}%</td>
                    </tr>
                    <tr>
                      <td>Proof type</td>
                      <td class="right-align">{coins?.proof_type}</td>
                    </tr>
                    <tr>
                      <td>24h Open</td>
                      <td class="right-align"><sup>$</sup>{formatPrice(coins?.open, 3)}</td>
                    </tr>
                    <tr>
                      <td>24h Low</td>
                      <td class="right-align"><sup>$</sup>{formatPrice(coins?.low, 3)}</td>
                    </tr>
                    <tr>
                      <td>24h High</td>
                      <td class="right-align"><sup>$</sup>{formatPrice(coins?.high, 3)}</td>
                    </tr>
                    <tr>
                      <td>Current Supply</td>
                      <td class="right-align">{formatPrice(coins?.supply, 5)} {id}</td>
                    </tr>
                    <tr>
                      <td>Total Supply</td>
                      <td class="right-align">{convertToInternationalCurrencySystem(coins?.supply)} {id}</td>
                    </tr>
                    <tr>
                      <td>Market cap</td>
                      <td class="right-align"><sup>$</sup>{convertToInternationalCurrencySystem(coins?.market_cap)}</td>
                    </tr>
                    <tr>
                      <td>24h Volume (coin)</td>
                      <td class="right-align">{convertToInternationalCurrencySystem(coins?.volume)} {id}</td>
                    </tr>
                    <tr>
                      <td>24h Volume (currency)</td>
                      <td class="right-align"><sup>$</sup>{convertToInternationalCurrencySystem(coins?.volume_ccy)}</td>
                    </tr>
                    <tr>
                      <td>24h Total volume (coin)</td>
                      <td class="right-align">{convertToInternationalCurrencySystem(coins?.total_volume)} {id}</td>
                    </tr>
                    <tr>
                      <td>24h Total volume (currency)</td>
                      <td class="right-align"><sup>$</sup>{convertToInternationalCurrencySystem(coins?.total_volume_ccy)}</td>
                    </tr>
                    <tr>
                      <td>Last updated</td>
                      <td class="right-align">{coins?.last_updated}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            }
            {tabId === 3 &&
              <div id="trades">
                <table class="bordered vertical-responsive-table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Market</th>
                      <th>Type</th>
                      <th class="right-align">Price</th>
                      <th class="right-align">Quantity</th>
                      <th class="right-align">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="">
                      <td data-title="ID">
                        <div>658709175</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Coinbase</td>
                      <td data-title="Type">
                        <span class="label_red"><i class="material-icons">arrow_downward</i>SELL</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,515.34</td>
                      <td data-title="Quantity" class="right-align">0.0005</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>32.68</td>
                    </tr>
                    <tr>
                      <td data-title="ID">
                        <div>6V0DCSNLSICQ</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">itBit</td>
                      <td data-title="Type">
                        <span class=""><i class="material-icons"></i>undefined</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,518.00</td>
                      <td data-title="Quantity" class="right-align">0.000084</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>5.09</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>658709174</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Coinbase</td>
                      <td data-title="Type">
                        <span class="label_green"><i class="material-icons">arrow_upward</i>BUY</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,515.33</td>
                      <td data-title="Quantity" class="right-align">0.0008</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>50.20</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>658709173</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Coinbase</td>
                      <td data-title="Type">
                        <span class="label_green"><i class="material-icons">arrow_upward</i>BUY</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,515.33</td>
                      <td data-title="Quantity" class="right-align">0.000025</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>1.51</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>658709172</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Coinbase</td>
                      <td data-title="Type">
                        <span class="label_red"><i class="material-icons">arrow_downward</i>SELL</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,515.34</td>
                      <td data-title="Quantity" class="right-align">0.0002</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>13.38</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>658709171</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Coinbase</td>
                      <td data-title="Type">
                        <span class="label_red"><i class="material-icons">arrow_downward</i>SELL</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,515.34</td>
                      <td data-title="Quantity" class="right-align">0.0008</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>47.53</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>658709170</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Coinbase</td>
                      <td data-title="Type">
                        <span class="label_red"><i class="material-icons">arrow_downward</i>SELL</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,515.34</td>
                      <td data-title="Quantity" class="right-align">0.0002</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>9.90</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>658709169</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Coinbase</td>
                      <td data-title="Type">
                        <span class="label_red"><i class="material-icons">arrow_downward</i>SELL</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,515.34</td>
                      <td data-title="Quantity" class="right-align">0.0041</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>247.25</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>658709168</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Coinbase</td>
                      <td data-title="Type">
                        <span class="label_red"><i class="material-icons">arrow_downward</i>SELL</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,515.34</td>
                      <td data-title="Quantity" class="right-align">0.0012</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>70.55</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>658709167</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Coinbase</td>
                      <td data-title="Type">
                        <span class="label_red"><i class="material-icons">arrow_downward</i>SELL</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,515.34</td>
                      <td data-title="Quantity" class="right-align">0.02</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>983.50</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>658709166</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Coinbase</td>
                      <td data-title="Type">
                        <span class="label_red"><i class="material-icons">arrow_downward</i>SELL</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,515.34</td>
                      <td data-title="Quantity" class="right-align">0.0016</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>99.25</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>658709165</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Coinbase</td>
                      <td data-title="Type">
                        <span class="label_red"><i class="material-icons">arrow_downward</i>SELL</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,515.34</td>
                      <td data-title="Quantity" class="right-align">0.000066</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>3.97</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>658709164</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Coinbase</td>
                      <td data-title="Type">
                        <span class="label_red"><i class="material-icons">arrow_downward</i>SELL</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,515.34</td>
                      <td data-title="Quantity" class="right-align">0.0016</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>96.73</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>658709163</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Coinbase</td>
                      <td data-title="Type">
                        <span class="label_red"><i class="material-icons">arrow_downward</i>SELL</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,515.34</td>
                      <td data-title="Quantity" class="right-align">0.0008</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>47.53</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>652096789</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Exmo</td>
                      <td data-title="Type">
                        <span class="label_red"><i class="material-icons">arrow_downward</i>SELL</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>63,205.80</td>
                      <td data-title="Quantity" class="right-align">0.0002</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>9.70</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>652096761</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Exmo</td>
                      <td data-title="Type">
                        <span class="label_red"><i class="material-icons">arrow_downward</i>SELL</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>63,206.96</td>
                      <td data-title="Quantity" class="right-align">0.10</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>6,491.35</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>652096721</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Exmo</td>
                      <td data-title="Type">
                        <span class="label_red"><i class="material-icons">arrow_downward</i>SELL</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>63,205.13</td>
                      <td data-title="Quantity" class="right-align">0.000080</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>5.07</td>
                    </tr>
                    <tr>
                      <td data-title="ID">
                        <div>6V0DCSNLSICG</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">itBit</td>
                      <td data-title="Type">
                        <span class=""><i class="material-icons"></i>undefined</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,518.00</td>
                      <td data-title="Quantity" class="right-align">0.0033</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>198.00</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>658709162</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Coinbase</td>
                      <td data-title="Type">
                        <span class="label_red"><i class="material-icons">arrow_downward</i>SELL</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,515.34</td>
                      <td data-title="Quantity" class="right-align">0.000019</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>1.16</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>658709161</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Coinbase</td>
                      <td data-title="Type">
                        <span class="label_red"><i class="material-icons">arrow_downward</i>SELL</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,515.34</td>
                      <td data-title="Quantity" class="right-align">0.000034</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>2.06</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>658709160</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Coinbase</td>
                      <td data-title="Type">
                        <span class="label_green"><i class="material-icons">arrow_upward</i>BUY</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,515.33</td>
                      <td data-title="Quantity" class="right-align">0.02</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>1,021.07</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>71430339</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Kraken</td>
                      <td data-title="Type">
                        <span class="label_green"><i class="material-icons">arrow_upward</i>BUY</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,537.00</td>
                      <td data-title="Quantity" class="right-align">0.0005</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>29.28</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>1720000597580-0</div>
                        <div class="time-ago">2 minutes ago</div>
                      </td>
                      <td data-title="Market">Cexio</td>
                      <td data-title="Type">
                        <span class="label_green"><i class="material-icons">arrow_upward</i>BUY</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,472.40</td>
                      <td data-title="Quantity" class="right-align">0.02</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>1,303.92</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>1720000551904-0</div>
                        <div class="time-ago">3 minutes ago</div>
                      </td>
                      <td data-title="Market">Cexio</td>
                      <td data-title="Type">
                        <span class="label_green"><i class="material-icons">arrow_upward</i>BUY</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,489.40</td>
                      <td data-title="Quantity" class="right-align">0.000075</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>4.55</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>1720000550476-0</div>
                        <div class="time-ago">3 minutes ago</div>
                      </td>
                      <td data-title="Market">Cexio</td>
                      <td data-title="Type">
                        <span class="label_green"><i class="material-icons">arrow_upward</i>BUY</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,489.40</td>
                      <td data-title="Quantity" class="right-align">0.0002</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>10.86</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>1720000550117-0</div>
                        <div class="time-ago">3 minutes ago</div>
                      </td>
                      <td data-title="Market">Cexio</td>
                      <td data-title="Type">
                        <span class="label_green"><i class="material-icons">arrow_upward</i>BUY</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,489.40</td>
                      <td data-title="Quantity" class="right-align">0.0005</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>32.59</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>2840140846483190</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Gemini</td>
                      <td data-title="Type">
                        <span class="label_red"><i class="material-icons">arrow_downward</i>SELL</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,522.21</td>
                      <td data-title="Quantity" class="right-align">0.000010</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>0.61</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>658709159</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Coinbase</td>
                      <td data-title="Type">
                        <span class="label_green"><i class="material-icons">arrow_upward</i>BUY</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,515.33</td>
                      <td data-title="Quantity" class="right-align">0.000061</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>3.68</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>71430338</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Kraken</td>
                      <td data-title="Type">
                        <span class="label_green"><i class="material-icons">arrow_upward</i>BUY</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,537.00</td>
                      <td data-title="Quantity" class="right-align">0.0005</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>29.00</td>
                    </tr>
                    <tr class="">
                      <td data-title="ID">
                        <div>71430337</div>
                        <div class="time-ago">a few seconds ago</div>
                      </td>
                      <td data-title="Market">Kraken</td>
                      <td data-title="Type">
                        <span class="label_green"><i class="material-icons">arrow_upward</i>BUY</span>
                      </td>
                      <td data-title="Price" class="right-align"><sup>$</sup>60,537.00</td>
                      <td data-title="Quantity" class="right-align">0.0003</td>
                      <td data-title="Total" class="right-align"><sup>$</sup>18.14</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            }

            {tabId === 4 &&
              <div id="social">
                <div class="col s12 m6">
                  <div class="fb-comments" data-numposts="5">
                    <iframe href="https://www.facebook.com/plugins/feedback.php?app_id&channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Dfcc9c1cb8b1c48f70%26domain%3Dmarkets.blockstar.site%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fmarkets.blockstar.site%252Ff6b2da940b2c84ba4%26relation%3Dparent.parent&container_width=0&height=100&href=https%3A%2F%2Fmarkets.blockstar.site%2Fcoin%2FBTC&locale=en_US&numposts=5&sdk=joey&title=Bitcoin%20(BTC)%20info%2C%20quotes%20and%20charts%20%7C%20BlockStar&url=https%3A%2F%2Fmarkets.blockstar.site%2Fcoin%2FBTC&version=v2.10&width=550&xid=https%253A%252F%252Fmarkets.blockstar.site%252Fcoin%252FBTC" title="app" />
                  </div>
                </div>
                {coins.twitter &&
                  <div id="twitter-timeline" class="col s12 m5 push-m1">
                    <a class="twitter-timeline" href={`https://twitter.com/${coins.twitter}`} data-chrome="noheader nofooter dark">
                      tweets
                    </a>
                    <TwitterTimelineEmbed username={coins.twitter} />
                  </div>
                }
              </div>
            }

            {tabId === 5 &&
              <div>
                <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                  <table className="striped dataTable no-footer" id="DataTables_Table_0" role="grid">
                    <thead>
                      <tr role="row">
                        <th className="sorting_desc" style={{ width: '0px' }}>Date</th>
                        <th className="right-align sorting" style={{ width: '0px' }}>Price</th>
                        <th className="right-align sorting" style={{ width: '0px' }}>Volume</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData.map((item, index) => (
                        <tr role="row" className={index % 2 === 0 ? 'even' : 'odd'} key={item.date}>
                          <td className="sorting_1">{item.date_fmt}</td>
                          <td className="right-align"><sup>$</sup>{item.open}</td>
                          <td className="right-align" data-order={item.volume}>{item.volume} {id}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                </div>
              </div>

            }

            {tabId === 6 &&
              <div id="widget">
                <div class="col s12">
                  <p>To embed real-time {coins?.name} widget into your website copy the following code.</p>
                </div>
                <div class="col s12 m6 l4">
                  <iframe title="frame" style={{ width: '100%', height: '180px', background: '#fff' }} frameBorder="0" scrolling="no" src={`https://markets.blockstar.site/widget?symbol=${coins?.symbol}&currency=USD&demo=1"`}></iframe>
                </div>
                <div class="input-field col s12 m6 l8">
                  <textarea id="widget-code" class="materialize-textarea" readonly="readonly">
                    {`<iframe style={{"width": "100%" , "height": "45px"}} frameBorder="0" scrolling="no" src=${`https://markets.blockstar.site/widget?symbol=${coins?.symbol}&currency=USD"`}></iframe>`}
                  </textarea>
                  <div class="right-align">
                    <CopyToClipboard text={`<iframe style={{"width": "100%" , "height": "45px"}} frameBorder="0" scrolling="no" src=${`https://markets.blockstar.site/widget?symbol=${coins?.symbol}&currency=USD"`}></iframe>`}
                      onCopy={() => {
                        setCopied(true)
                        setTimeout(() => {
                          setCopied(false)
                        }, 3000)
                      }}>
                      <button id="copy-widget-code-button" class="blue waves-effect waves-light btn">
                        {copied ? (
                          <span>Copied</span>
                        ) : (
                          <>
                            <i class="material-icons left">content_copy</i> Copy widget code
                          </>
                        )}
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>

            }
          </div>
        </section>
      </section >

    </Layout>
  );
};
