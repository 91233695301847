import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { CryptoProvider } from './context/CryptoContext';
import { TrendingProvider } from './context/TrendingContext';
import { StorageProvider } from './context/StorageContext';
import { GlobalProvider } from './context/GlobalContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './providers/AuthProvider';
import { BrowserRouter } from 'react-router-dom';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalProvider>
        <CryptoProvider>
          <TrendingProvider>
            <StorageProvider>
              <AuthProvider>
                <ToastContainer />
                <App />
              </AuthProvider>
            </StorageProvider>
          </TrendingProvider>
        </CryptoProvider>
      </GlobalProvider>
    </BrowserRouter>

  </React.StrictMode>
);