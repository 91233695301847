import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Layout from '../components/Layout';

const faqData = [
  {
    question: "What is Market Capitalization and how is it calculated?",
    answer: "Market Capitalization is one way to rank the relative size of a cryptocurrency. It's calculated by multiplying the Price by the Circulating Supply.Market Cap = Price X Circulating Supply."
  },
  {
    question: "What is the difference between Circulating Supply, Total Supply and Max Supply?",
    answer: "Circulating Supply is the best approximation of the number of coins that are circulating in the market and in the general public's hands. Total Supply is the total amount of coins in existence right now (minus any coins that have been verifiably burned). Max Supply the best approximation of the maximum amount of coins that will ever exist in the lifetime of the cryptocurrency."
  },
  {
    question: "Why is the Circulating Supply used in determining the market capitalization instead of Total Supply?",
    answer: "Circulating Supply is a much better metric for determining the market capitalization. Coins that are locked, reserved, or not able to be sold on the public market are coins that can't affect the price and thus should not be allowed to affect the market capitalization as well. The method of using the Circulating Supply is analogous to the method of using public float for determining the market capitalization of companies in traditional investing."
  },
  // Add more FAQ items here
];

export default function Faqs() {
  return (
    <Layout>
      <div className='container mt-5'>
        <header>
          <div className="row">
            <div className="col s12">
              <h1 className="primary-text">FAQ</h1>
              <div className="divider"></div>
            </div>
          </div>
        </header>
        <article className="section">
          {faqData.map((faq, index) => (
            <Accordion key={index} className='mb-5 '>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                {faq.question}
              </AccordionSummary>
              <AccordionDetails>
                {faq.answer}
              </AccordionDetails>
            </Accordion>
          ))}
        </article>
      </div>
    </Layout>
  );
}
