import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const getPaginationNumbers = () => {
    const range = [];
    const rangeSize = 3; // Number of pages to show around the current page
    const start = Math.max(1, currentPage - rangeSize);
    const end = Math.min(totalPages, currentPage + rangeSize);

    if (start > 1) {
      range.push(1);
      if (start > 2) range.push('...');
    }

    for (let i = start; i <= end; i++) {
      range.push(i);
    }

    if (end < totalPages) {
      if (end < totalPages - 1) range.push('...');
      range.push(totalPages);
    }

    return range;
  };

  const paginationNumbers = getPaginationNumbers();

  return (
    <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
      <button
        className={`waves-effect btn btn-small previous ${currentPage === 1 ? 'disabled' : ''}`}
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      <span>
        {paginationNumbers.map((number, index) =>
          number === '...' ? (
            <span key={index} className="ellipsis">…</span>
          ) : (
            <button
              key={number}
              className={`waves-effect btn btn-small ${currentPage === number ? 'current' : ''}`}
              onClick={() => onPageChange(number)}
            >
              {number}
            </button>
          )
        )}
      </span>
      <button
        className={`waves-effect btn btn-small next ${currentPage === totalPages ? 'disabled' : ''}`}
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
