import React, { useEffect, useState } from 'react'
import { api } from '../helper/constant';
import logoImg from '../img/logo-udf.png';
import { Link } from 'react-router-dom';

export default function Footer() {
    const [risers, setRisers] = useState([])
    const [fallers, setFallers] = useState([])

    useEffect(() => {
        const fetchCoins = async () => {

            try {
                const response = await api.get(`coins/topcoins`);
                if (response && response.data) {
                    setRisers(response.data.risers);
                    setFallers(response.data.fallers);
                }
            } catch (error) {
                console.error('Error fetching coins:', error);
            }
        };

        fetchCoins();
    }, []);


    return (
        <footer class="page-footer">
            <div class="container section">
                <div class="row">
                    <div class="col s12 m12 l6">
                        <h5>
                            <img src={logoImg} alt='logo' />
                        </h5>
                        <br />
                        <p>
                            BlockStar is a PHP web application, which displays general information, real-time quotes, trades, interactive historical charts and twitter timeline feed for more than 2000 cryptocurrencies.
                        </p>
                    </div>
                    <div class="col s12 m6 l3">
                        <h5><span>Top risers</span></h5>
                        <ul>
                            {risers && risers.map((items, key) => (
                                <li key={key}><a href="#sec">{items.name}</a><span class="right change change-up">{items.change_pct}%</span></li>
                            ))}
                        </ul>
                        <a href="#sec" class="right"><i class="material-icons">keyboard_arrow_right</i>View all</a>
                    </div>
                    <div class="col s12 m6 l3">
                        <h5><span>Top fallers</span></h5>
                        <ul>
                            {fallers && fallers.map((items, key) => (
                                <li key={key}><a href="#sec">{items.name}</a><span class="right change change-down">{items.change_pct}%</span></li>
                            ))}
                        </ul>
                        <a href="#sec" class="right"><i class="material-icons">keyboard_arrow_right</i>View all</a>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="divider"></div>
            </div>
            <div class="footer-copyright">
                <div class="container section">
                    <div class="row">
                        <div class="col s12 m6">
                            <Link to="/faqs">FAQ</Link>
                        </div>
                        <div class="col s12 m6 right-align">
                            ©BlockStar | All Rights Reserved.
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
